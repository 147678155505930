import axios from 'axios';
import {IP_WITH_PORT} from "../config"
import { REGISTER_SUCCESS, REGISTER_FAIL, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from './types';
import { clearCart } from './cartActions';
axios.defaults.baseURL =`${IP_WITH_PORT}`;
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const registerUser = (userData) => async (dispatch) => {
  try {
    const response = await axios.post('/auth/register', userData);
    const { token } = response.data;
    localStorage.setItem('token', token);
    dispatch({ type: REGISTER_SUCCESS, payload: token });
  } catch (error) {
    dispatch({ type: REGISTER_FAIL, payload: error.response.data.message });
  }
};

export const loginUser = (userData) => async (dispatch) => {
  try {
    console.log('Sending userData:', userData); // Debug the request
    const response = await axios.post('/admin/loginAdmin', userData);

    console.log('Response received:', response); // Debug the response
    const { token } = response.data;

    // Save the token
    localStorage.setItem('token', token);

    // Notify success
    toast.success('Login successful!', {
      position: 'top-right',
      autoClose: 3000,
    });

    // Dispatch success action
    dispatch({ type: LOGIN_SUCCESS, payload: token });
  } catch (error) {
    console.error('Login error:', error);

    const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';

    // Notify error
    toast.error(errorMessage, {
      position: 'top-right',
      autoClose: 3000,
    });

    // Dispatch failure action
    dispatch({ type: LOGIN_FAIL, payload: errorMessage });
  }
};


export const logoutUser = () => (dispatch) => {
    localStorage.removeItem('token');
    dispatch({ type: LOGOUT });
    dispatch(clearCart());
  };
